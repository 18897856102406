import { Component, OnInit } from '@angular/core';

import { BONES, MUSIC } from '../content/photos';
import { AUDIO } from '../content/audio';
import { BONES as BONESCDs } from '../content/cds';

@Component({
  selector: 'app-bonesapart',
  templateUrl: './bonesapart.component.html',
  styleUrls: ['./bonesapart.component.css']

})
export class BonesapartComponent implements OnInit {
audio = AUDIO;
photos = BONES;
cds = BONESCDs;
music = MUSIC;

  constructor() { }

  ngOnInit(): void {
    console.log(this.audio[0])
  }

}
