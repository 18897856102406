<app-nav></app-nav>
<div class="content">
  <div class="row">
    <div class="col-md-4">
      <img src="../../assets/imgs/teaching/teaching3.jpg" alt="" />
    </div>
    <div class="col-md-8 light-text-box">
      <h3>Educational Work</h3>
      <p>
        I am often invited to guest teach at music conservatoires throughout the
        UK, which is something I find really rewarding. It is a privilege to
        have an opportunity to nurture students and enable their learning and
        musical development. As well as individual lessons, I enjoy giving
        chamber ensemble coaching and orchestral section training. I have
        recently become Visiting Artist in Trombone at the Royal Birmingham
        Conservatoire, and other guest visits include Guildhall School of Music
        and Drama, Royal Academy of Music, Royal Northern College of Music and
        the Royal Conservatoire of Scotland. I have given masterclasses at Wells
        Cathedral and Purcell Schools, coached the National Youth Orchestra and
        National Youth Concert Band trombone sections, and was on the artist
        faculty for the 2017 Dublin Brass Week. I have adjudicated for a number
        of competitions, including BBC Young Musician, Royal Over-Seas League,
        BBC Radio 2 Young Brass Award, Ian Bousfield/Getzen International Solo,
        Swisstbone, International Trombone Association and British Trombone
        Society competitions.
      </p>
      <img src="../../assets/imgs/teaching/wells_panorama.JPG" alt="" />
    </div>
  </div>

  <app-photoshow [data]="photos"></app-photoshow>
</div>
