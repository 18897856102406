<div id="hero">
  <div class="row">
    <div class="col-md-6 hero-text">
      <div>
        <h1 class="name">Helen Vollam</h1>
        <h3>trombone</h3>
      </div>
      <app-quote-ticker [data]="quoteArray"></app-quote-ticker>
    </div>
    <div class="col-md-6">
      <div class="image-crop">
        <img
          class="hero-image"
          id="heroimage"
          src="../assets/imgs/portrait4.JPG"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
