import { Component, OnInit } from '@angular/core';
import { BBC, BONES, OTHER, COE } from '../content/cds';
import { FILMS } from '../content/films';
import { AUDIO } from '../content/audio';
import { FILMS as CAROUSEL } from '../content/films';


@Component({
  selector: 'app-recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.css']
})
export class RecordingComponent implements OnInit {
  
  bbc = BBC;
  bones = BONES;
  coe = COE;
  other = OTHER;
  films = FILMS;
  audio = AUDIO;
  carousel = CAROUSEL;

constructor(){}
  
  

  ngOnInit(): void {
  }

}
