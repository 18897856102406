import { Component, OnInit } from "@angular/core";
import { AUDIO } from "../content/audio";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "app-audio-player",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["./audio-player.component.css"],
})
export class AudioPlayerComponent implements OnInit {
  currentAudio = null;
  currentTime: any;
  currentAudioDuration: any;
  selectedAudio: any;
  listAudio = AUDIO;
  audioPlayer: any;
  isPlaying = false;
  selectedIndex: number;
  artists: string[];
  selectedArtist = "All Artists";
  collapsed = false;
  muted = false;

  indexAudio: number;
  btnMute: any;
  volUp: any;
  volMute: any;

  constructor() {
    this.audioPlayer = document.getElementById("myAudio");
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
  }

  previous() {
    if (this.selectedIndex > 0) {
      this.audioPlayer.pause();
      this.selectedIndex = this.selectedIndex - 1;
      this.selectedAudio = this.listAudio[this.selectedIndex];
      this.audioPlayer.src = this.selectedAudio.audio;
      this.audioPlayer.play();
    }
  }

  next() {
    if (this.selectedIndex < this.listAudio.length - 1) {
      this.audioPlayer.pause();
      this.selectedIndex = this.selectedIndex + 1;
      this.selectedAudio = this.listAudio[this.selectedIndex];
      this.audioPlayer.src = this.selectedAudio.audio;
      this.audioPlayer.play();
    }
  }

  toggleAudio(track) {
    this.selectedAudio = track;

    //Skip loading if current file hasn't changed.
    if (track.audio !== this.currentAudio) {
      this.audioPlayer.src = track.audio;
    }

    // Tests the paused attribute and set state.
    if (this.audioPlayer.paused) {
      this.audioPlayer.play();
      this.isPlaying = true;
      setInterval(() => {
        this.currentAudioDuration = this.audioPlayer.duration;
        this.currentTime = this.audioPlayer.currentTime;
      }, 100);
      return;
    }
    // else {
    //   this.audioPlayer.pause();
    //   this.isPlaying = false;
    //   clearInterval();
    // }
  }

  pause() {
    console.log("paused");
    this.audioPlayer.pause();
    this.isPlaying = false;
    clearInterval();
  }

  filterArtist(artist) {
    this.listAudio = AUDIO.filter((item) => item.artist === artist);
    this.selectedArtist = artist;
  }

  showAll() {
    this.listAudio = AUDIO;
    this.selectedArtist = "All Artists";
  }

  forward() {
    this.audioPlayer.currentTime += 30.0;
  }

  rewind() {
    this.audioPlayer.currentTime -= 30.0;
  }

  toggleMute() {
    if (!this.audioPlayer.muted) {
      this.audioPlayer.muted = true;
    } else {
      this.audioPlayer.muted = false;
    }
    this.muted = !this.muted;
  }

  ngOnInit(): void {
    this.audioPlayer = document.getElementById("myAudio");
    this.btnMute = document.querySelector("#toggleMute");
    this.volUp = document.querySelector("#icon-vol-up");
    this.volMute = document.querySelector("#icon-vol-mute");
    this.selectedAudio = this.listAudio[0];
    this.selectedIndex = 0;
    this.artists = [...new Set(AUDIO.map((track) => track.artist))];
  }
}
