import { Component, OnInit } from '@angular/core';
import { FILMS } from '../content/films';
import { BBC } from '../content/cds';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
films = FILMS;
cds = BBC;

  constructor() { }

  

  ngOnInit(): void {
  }



}
