import { Component, OnInit } from "@angular/core";
import {
  DomSanitizer,
  SafeUrl,
  SafeResourceUrl,
} from "@angular/platform-browser";
import { AUDIO } from "../content/audio";
import { VIDEOS } from "../content/videos";

@Component({
  selector: "app-media",
  templateUrl: "./media.component.html",
  styleUrls: ["./media.component.css"],
})
export class MediaComponent implements OnInit {
  videos: any;
  videoWidth: number;
  currentSlide = 1;
  selectedTrack: string;
  videoList: any;
  BBCVideos: any;
  BonesApartVideos: any;
  COEVideos: any;
  soloVideos: any;
  otherVideos: any;
  selectedVideo: any;
  selectedVideoIndex = 0;
  showModal = false;
  audio = AUDIO;

  constructor(private sanitizer: DomSanitizer) {}

  setTrack(track) {
    console.log(track);
    this.selectedTrack = null;
    setTimeout(() => (this.selectedTrack = track), 10);
  }

  sanitiser(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  selectVideo(vid) {
    let index = this.videoList.findIndex((item) => item.id === vid.id);
    this.selectedVideoIndex = index;
    console.log(this.videoList[index]);
    vid !== null ? (this.showModal = true) : (this.showModal = false);
  }

  nextVid() {
    if (this.selectedVideoIndex < this.videoList.length - 1)
      this.selectedVideoIndex = this.selectedVideoIndex + 1;
  }

  prevVid() {
    if (this.selectedVideoIndex > 0)
      this.selectedVideoIndex = this.selectedVideoIndex - 1;
  }

  closeModal() {
    this.showModal = false;
  }

  ngOnInit(): void {
    this.videoList = VIDEOS.map((url) => {
      return { ...url, url: this.sanitiser(url.url) };
    });

    this.BonesApartVideos = VIDEOS.map((url) => {
      return url.url.includes("youtube")
        ? { ...url, url: this.sanitiser(url.url) }
        : url;
    }).filter((v) => v.artist == "Bones Apart");

    this.soloVideos = VIDEOS.map((url) => {
      return { ...url, url: this.sanitiser(url.url) };
    }).filter((v) => v.artist == "Helen Vollam & Elizabeth Burley");
  }
}
