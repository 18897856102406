<app-nav></app-nav>


<div class="content">

<h3>Gallery</h3>
<p>High resolution versions of these photos and an up to date biography can be downloaded at the bottom of this page.</p>
<div class="row">
    <div class="col-md-4 col-sm-6">
        <img src="../../assets/imgs/Gallery/Helen V bonesapart-65.jpg" alt="">
        <img src="../../assets/imgs/Gallery/Helen Vollam - Hi Res-2.JPG" alt=""></div>

    <div class="col-md-4 col-sm-6"><img src="../../assets/imgs/Gallery/Helen V - Hi Res-116.jpg" alt=""></div>
    <div class="col-md-4 col-sm-6"><img src="../../assets/imgs/Gallery/Helen with tbn.JPG" alt=""></div>
    <div class="col-md-4 col-sm-6"><img src="../../assets/imgs/Gallery/Helen_MG_1451-1.jpg" alt=""></div>
    <div class="col-md-4 col-sm-6"><img src="../../assets/imgs/Gallery/Helen_MG_1451-2.jpg" alt=""></div>

</div>
<a class="btn btn-dark btn-sm" href="../../assets/downloads.zip" download="HelenVollam_Biog&Photos">Download Biog & Photos <i class="fa fa-download"></i></a>
</div>