export const VIDEOS = [
  {
    id: 1,
    label: "Air Moving",
    artist: "Helen Vollam & Elizabeth Burley",
    url: "https://www.youtube.com/embed/6i0W9YjWOfY?rel=0",
  },
  {
    id: 2,
    label: "Allerseelen",
    artist: "Helen Vollam & Elizabeth Burley",
    url: "https://www.youtube.com/embed/Ox_0yeYZq78?rel=0",
  },
  {
    id: 3,
    label: "Mabuika",
    artist: "Helen Vollam & Elizabeth Burley",
    url: "https://www.youtube.com/embed/97gX5Ixcfkk?rel=0",
  },
  {
    id: 4,
    label: "Si dolce è il Tormeto",
    artist: "Helen Vollam & Elizabeth Burley",
    url: "https://www.youtube.com/embed/o88pxc_B4co?rel=0",
  },
  {
    id: 5,
    label: "D'un Barbaro Scortese by G F Handel (arr Vollam)",
    artist: "Helen Vollam & Elizabeth Burley",
    url: "https://www.youtube.com/embed/pr1W3UnsVGI?rel=0",
  },
  {
    id: 6,
    label: "Parhelia from Book of Miracles by Gavin Higgins",
    artist: "Helen Vollam & Elizabeth Burley",
    url: "https://www.youtube.com/embed/wzxzvde2mD4?rel=0",
  },
  {
    id: 7,
    label: "video 4",
    artist: "Bones Apart",
    url: "https://www.youtube.com/embed/eUy3dTZIk1M?rel=0",
    youtube: true,
  },
  {
    id: 8,
    label: "Chanson - Nadia Boulanger",
    artist: "Bones Apart",

    url: "https://youtube.com/embed/UuOqUh6xenU?rel=0",
    youtube: true,
  },
  {
    id: 9,
    label: "video 5",
    artist: "Bones Apart",
    url: "https://www.youtube.com/embed/L22gnbFMoRc?rel=0",
    youtube: true,
  },
  {
    id: 10,
    label: "video 6",
    artist: "Bones Apart",
    url: "https://www.youtube.com/embed/yiII2nMiass?rel=0",
    youtube: true,
  },
  {
    id: 11,
    label: "video 6",
    artist: "Bones Apart",
    url: "https://www.youtube.com/embed/zP419bPv33o?rel=0",
    youtube: true,
  },
  {
    id: 12,
    label: "My Funny Valentine",
    artist: "Bones Apart",
    url: "https://www.youtube.com/embed/1w0IWU15vRc?rel=0",
    youtube: true,
  },
  {
    id: 13,
    label: "Variations on Annie Laurie",
    artist: "Bones Apart",
    url: "https://www.youtube.com/embed/n-ei0c5i-fw?rel=0",
    youtube: true,
  },
  {
    id: 14,
    label: "Habanera",
    artist: "Bones Apart",
    url: "https://www.youtube.com/embed/dXzuuo5-3MY?rel=0",
  },
  {
    id: 15,
    label: "Seguidille",
    artist: "Bones Apart",
    url: "https://www.youtube.com/embed/CjDydMNZtcQ?rel=0",
    youtube: true,
  },

  {
    id: 16,
    label: "video 7",
    artist: "BBCSO",
    url: "https://www.youtube.com/embed/9Ax8lyvuIjY?rel=0",
    youtube: true,
  },
  {
    id: 17,
    label: "video 7",
    artist: "BBCSO",
    url: "https://www.youtube.com/embed/fE0RbPsC9uE?rel=0",
    youtube: true,
  },
  {
    id: 18,
    label: "video 7",
    artist: "BBCSO",
    url: "https://www.youtube.com/embed/8gqp8GIa62c?rel=0",
    youtube: true,
  },
  {
    id: 19,
    label: "video 7",
    artist: "BBCSO",
    url: "https://www.youtube.com/embed/i66SG_AMczg?rel=0",
    youtube: true,
  },
  {
    id: 20,
    label: "video 7",
    artist: "BBCSO",
    url: "https://www.youtube.com/embed/ZTCNnpVxW2c?rel=0",
    youtube: true,
  },
  {
    id: 21,
    label: "video 7",
    artist: "BBCSO",
    url: "https://youtube.com/embed/MNlN_e5b65o?rel=0",
    youtube: true,
  },
  {
    id: 22,
    label: "video 7",
    artist: "Bones Apart",
    url: "../assets/video/RossiUnBarbaro.mp4?rel=0",
  },

  {
    id: 23,
    label: "video 7",
    artist: "other",

    url: "https://youtube.com/embed/Il5zJxk_DoE?rel=0",
    youtube: true,
  },
];
