<audio id="myAudio">
  <!-- <source src="audio.ogg" type="audio/ogg"> -->
  <source id="source-audio" src="" type="audio/mpeg" />
  Your browser does not support the audio element.
</audio>

<div
  id="container"
  class="player-wrapper"
  [ngClass]="!collapsed ? 'player-ctn' : 'player-ctn collapsed'"
>
  <span *ngIf="!collapsed" (click)="toggleCollapse()" class="show-hide-text"
    >hide audio player</span
  >
  <span *ngIf="collapsed" (click)="toggleCollapse()" class="show-hide-text"
    >show audio player</span
  >
  <div class="infos-ctn">
    <div class="now-playing-text">
      <span class="title">{{ selectedAudio.title }}</span>
      <span class="artist">{{ selectedAudio.artist }}</span>
    </div>
    <img
      *ngIf="!collapsed && selectedAudio.cover"
      class="cover"
      src="{{ selectedAudio.cover }}"
    />
  </div>
  <div class="progress-bar-container">
    <progress
      class="audio-progress-bar"
      id="seekbar"
      value="{{ currentTime }}"
      max="{{ currentAudioDuration }}"
    ></progress>
  </div>

  <div class="btn-ctn">
    <div class="btn-action first-btn" (click)="previous()">
      <div id="btn-faws-back">
        <i class="fas fa-step-backward"></i>
      </div>
    </div>
    <div class="btn-action" (click)="rewind()">
      <div id="btn-faws-rewind">
        <i class="fas fa-backward"></i>
      </div>
    </div>
    <div class="btn-action">
      <div id="btn-faws-play-pause">
        <i
          (click)="toggleAudio(selectedAudio)"
          *ngIf="!isPlaying"
          class="fas fa-play"
          id="icon-play"
        ></i>
        <i
          (click)="pause()"
          *ngIf="isPlaying"
          class="fas fa-pause"
          id="icon-pause"
        ></i>
      </div>
    </div>
    <div class="btn-play" (click)="forward()">
      <div id="btn-faws-forward">
        <i class="fas fa-forward"></i>
      </div>
    </div>
    <div class="btn-action" (click)="next()">
      <div id="btn-faws-next">
        <i class="fas fa-step-forward"></i>
      </div>
    </div>
    <div class="btn-mute" (click)="toggleMute()">
      <div>
        <i *ngIf="!muted" class="fas fa-volume-up"></i>
        <i *ngIf="muted" class="fa-solid fa-volume-xmark"></i>
      </div>
    </div>
  </div>
  <div class="player-lists">
    <!-- <div class="filter-list">
      <p class="filter-heading"><b>Filter by Artist</b></p>

      <div class="playlist-choice">
        <span (click)="showAll()" class="playlist-choice-button">Show all</span>

        <div *ngFor="let artist of artists">
          <span (click)="filterArtist(artist)" class="playlist-choice-button">{{
            artist
          }}</span>
        </div>
      </div>
    </div> -->
    <div class="playlist-ctn tracks-list-container">
      <!-- <p>
        <b>Showing {{ selectedArtist }}</b>
      </p> -->
      <div class="tracks-list">
        <div *ngFor="let track of listAudio">
          <button
            (click)="toggleAudio(track)"
            [ngClass]="
              track === selectedAudio
                ? 'play-list-track playlist-track-active'
                : 'playlist-track'
            "
          >
            {{ track.title }} - {{ track.artist }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
