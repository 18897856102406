<app-nav></app-nav>
<div class="content">
  <div class="row header">
    <div class="col-md-6 text-justify">
      <img id="section" src="../../assets/imgs/coe/COE1.jpg" alt="" />

      <div>
        <a href="https://www.facebook.com/ChamberOrchestraofEurope/"
          ><i class="fab fa-facebook-square fa-2x"></i
        ></a>
        <a href="https://twitter.com/chamborcheurope?lang=en"
          ><i class="fab fa-twitter fa-2x"></i
        ></a>
        <a
          href="https://music.apple.com/us/artist/chamber-orchestra-of-europe/4345835"
          ><i class="fab fa-itunes fa-2x"></i
        ></a>
      </div>
    </div>
    <div class="col-md-6 text-justify light-text-box">
      <img id="logo" src="../../assets/imgs/COE.png" alt="" />

      <p>
        My first tour with the Chamber Orchestra of Europe was in 1997, playing
        Schubert in Salzburg conducted by Claudio Abbado. Plenty of lovely stuff
        for the trombones to play and what a treat to be on stage with such an
        incredible group of musicians. Since then I have been involved in
        countless tours around Europe, the USA and Japan, working with some
        wonderful conductors like Nikolaus Harnoncourt, Yannick Nézet-Séguin and
        Bernard Haitink, to name but a few. My COE colleagues are Håkan Björkman
        (Principal Trombone), Nicholas Eastop (Bass Trombone) and Jens
        Bjørn-Larsen (Tuba). For more information, please visit
        <a href="http://www.coeurope.org">www.coeurope.org</a>
      </p>
      <div class="row audioplayer">
        <div class="col-md-9 text-left">
          <h6>{{ audio[2].title }}</h6>
          <small>{{ audio[2].artist }}, {{ audio[2].info }}</small>
          <audio controls>
            <source src="{{ audio[2].audio }}" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
        <div class="col-md-3 d-none d-md-inline">
          <img
            style="border-radius: 10px"
            src="../../assets/imgs/CDs/CD17.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <div id="middle">
    <h4>Photo Gallery</h4>

    <app-photoshow [data]="photos"></app-photoshow>
  </div>
  <h4>Recordings</h4>
  <app-photoshow [data]="cds"></app-photoshow>
</div>
