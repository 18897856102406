<div class="group-2">
    <img
      *ngFor="let img of data; let i = index"
      [src]="img.image"
      (click)="showLightbox(i)"
    />
  </div>

  <ng-image-fullscreen-view
    [images]="data"
    [imageIndex]="currentIndex"
    [show]="showFlag"
    (close)="closeEventHandler()"
  >
  </ng-image-fullscreen-view>
