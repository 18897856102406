import { Component, OnInit } from "@angular/core";

import { BBC } from "../content/photos";
import { BBC as BBCFilms } from "../content/youtube";
import { BBC as CDs } from "../content/cds";

@Component({
  selector: "app-bbc",
  templateUrl: "./bbc.component.html",
  styleUrls: ["./bbc.component.css"],
})
export class BbcComponent implements OnInit {
  photos = BBC.reverse();
  video = BBCFilms;
  cds = CDs;
  headphotos = [
    { image: "../../assets/imgs/bbc/BBC1.jpg" },
    { image: "../../assets/imgs/bbc/BBC2.jpg" },
    { image: "../../assets/imgs/bbc/BBC5.jpg" },
    { image: "../../assets/imgs/bbc/BBC10.jpg" },
    { image: "../../assets/imgs/bbc/BBC11.jpg" },
  ];

  constructor() {}

  ngOnInit(): void {}
}
