import { Component, OnInit } from '@angular/core';
import { BIOG } from '../content/biography';

@Component({
  selector: 'app-biog',
  templateUrl: './biog.component.html',
  styleUrls: ['./biog.component.css']
})
export class BiogComponent implements OnInit {
biog = BIOG;
  constructor() { }

  ngOnInit(): void {
  }

}
