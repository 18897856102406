import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: "app-quote-ticker",
  templateUrl: "./quote-ticker.component.html",
  styleUrls: ["./quote-ticker.component.css"],
  animations: [
    trigger("quoteAnimation", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate("500ms", style({ opacity: 1 })),
      ]),
      transition("* => void", [animate("500ms", style({ opacity: 0 }))]),
    ]),
  ],
})
export class QuoteTickerComponent implements OnInit {
  constructor() {}

  @Input() data: [];

  quoteIndex = 0;

  ngOnInit(): void {
    setInterval(() => {
      this.onNextClick();
    }, 6000);
  }

  onNextClick() {
    const next = this.quoteIndex + 1;
    this.quoteIndex = next === this.data.length ? 0 : next;
  }
}
