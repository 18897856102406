<app-nav></app-nav>

<div class="content">
  <div class="row top">
    <div class="col-md-4">
      <img src="../../assets/imgs/bonesapart1.jpg" id="portrait" alt="" />
    </div>

    <div class="col-md-5 light-text-box">
      <h3>Recording</h3>
      <p>
        A big part of the music industry is recording and I am lucky to have
        been involved in a variety of CD and film soundtrack recordings, some of
        which are shown here. Ranging from symphony and chamber orchestra
        recordings of Sibelius, Walton, John Adams, Brahms and Mozart to
        blockbuster movie soundtracks for <em>Inception,</em> Star Wars:
        <em>Attack of the Clones,</em> three <em>Harry Potter</em> films,
        <em>Iron Man 2, Hotel Transylvania </em>and <em>Brave</em>.
      </p>
    </div>
    <div class="col-md-3">
      <carousel [slides]="films"></carousel>
    </div>
  </div>

  <!-- MIDDLE SECTION -->
  <!-- <div class="row tracks">
    <div *ngFor="let track of audio" class="col-md-3 track">
      <div>
        <p>
          <span
            ><em>{{ track.title }}</em></span
          >
          - <span>{{ track.artist }}</span
          ><br /><small>{{ track.info }}</small>
        </p>
      </div>
      <audio controls>
        <source src="{{ track.audio }}" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  </div> -->

  <div class="text-center cds">
    <h5>BBC Symphony Orchestra</h5>
    <app-photoshow [data]="bbc"></app-photoshow>

    <h5>Bones Apart</h5>
    <app-photoshow [data]="bones"></app-photoshow>

    <h5>Chamber Orchestra of Europe</h5>
    <app-photoshow [data]="coe"></app-photoshow>

    <h5>Other Orchestras and Ensembles</h5>
    <app-photoshow [data]="other"></app-photoshow>

    <h4>Film Soundtracks</h4>
    <app-photoshow [data]="films"></app-photoshow>
  </div>
</div>
