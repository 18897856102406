import { Component, OnInit } from "@angular/core";
import {
  COE,
  BBC,
  BONES,
  TEACHING,
  HIGGINS,
  OTHER,
  FIRST,
  TRIO,
} from "../content/photos";

@Component({
  selector: "app-photos",
  templateUrl: "./photos.component.html",
  styleUrls: ["./photos.component.css"],
})
export class PhotosComponent implements OnInit {
  photos = [
    ...OTHER,
    ...BBC,
    ...COE,
    ...TEACHING,
    ...HIGGINS,
    ...BONES,
    ...FIRST,
    ...TRIO,
  ];
  constructor() {}

  ngOnInit(): void {}
}
