<app-nav></app-nav>

<div class="content">
  <div class="row">
    <div class="col-md-4">
      <img src="../../assets/imgs/bones/BAcover.jpg" alt="" />

      <div>
        <div>
          <a href="https://www.facebook.com/watch/bonesapart/"
            ><i class="fab fa-facebook-square fa-2x"></i
          ></a>

          <a href="https://www.youtube.com/user/BonesApartTbn4tet"
            ><i class="fab fa-youtube-square fa-2x"></i>
          </a>

          <a href="https://www.instagram.com/bones_apart_/"
            ><i class="fab fa-instagram fa-2x"></i
          ></a>
          <a href="https://twitter.com/bonesapart"
            ><i class="fab fa-twitter fa-2x"></i
          ></a>
          <a href="https://itunes.apple.com/gb/artist/bones-apart/id265818464"
            ><i class="fab fa-itunes fa-2x"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="col-md-8 text-center">
      <div class="text-justify light-text-box">
        <img id="logo" src="../../assets/imgs/bones/BATQ Logo.png" alt="" />

        <p>
          I joined the trombone quartet Bones Apart in 2007 and can honestly say
          there has never been a dull moment! It’s great fun! We have enjoyed
          performing concerts and giving masterclasses all over the world and
          have recorded four CDs. We are constantly refreshing and expanding our
          repertoire with imaginative new programming ideas. We like to
          challenge our audience, and ourselves, as to what is possible on the
          instrument, and we relish the opportunity to bring the trombone
          quartet to new audiences. > My Bones Apart colleagues are Becky Smith
          and Jayne Murrill (Tenor Trombones), and Sarah Williams (Bass
          Trombone). Find out more about Bones Apart at
          <a href="http://www.bonesapart.com/">www.bonesapart.com</a>
        </p>

        <h4 class="title">Arranging</h4>
        <p>
          I arrange a lot of the repertoire for Bones Apart and many of these
          pieces are available to buy from
          <a target="_blank" href="http://bonesapartpublishing.com"
            >www.bonesapartpublishing.com</a
          >
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div
        [ngClass]="track.artist !== 'Bones Apart' ? 'hidden' : null"
        *ngFor="let track of audio"
        class="audioplayer"
      >
        <div class="row">
          <div class="col-md-9">
            <h6>{{ track.title }}</h6>
            <small>{{ track.info }}</small>
            <audio controls>
              <source src="{{ track.audio }}" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
          <div class="col-md-3 d-none d-md-inline">
            <img style="border-radius: 10px" src="{{ track.cover }}" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 text-center">
      <video poster="../../assets/imgs/bones/ww.jpg" controls>
        <source src="../../assets/video/RossiUnBarbaro.mp4" />
      </video>
    </div>
    <div class="col-md-4">
      <iframe
        src="https://www.youtube.com/embed/eUy3dTZIk1M"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/L22gnbFMoRc"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/yiII2nMiass"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/zP419bPv33o"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <h4 class="title">Photo Gallery</h4>
  <app-photoshow [data]="photos"></app-photoshow>
  <h4>Recordings</h4>
  <app-photoshow [data]="cds"></app-photoshow>
</div>
