<div class="video-carousel-wrapper">
  <div id="carousel" class="video-carousel">
    <div *ngFor="let video of videos; let i = index">
      <div id="video" class="fake-vid">
        <iframe
          *ngIf="i == currentSlide"
          id="video"
          [src]="video.url"
          frameborder="0"
          allow="accelerometer; encrypted-media;
            gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</div>
<div class="nav-buttons">
  <button class="nav-button left" (click)="back()">
    <div>back</div>
  </button>

  <button class="nav-button right" (click)="next()">
    <div>next</div>
  </button>
  <span class="video-count">{{ currentSlide + 1 }} / {{ videos.length }}</span>
</div>
