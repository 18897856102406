import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { VIDEOS } from "../content/videos";

@Component({
  selector: "app-video-carousel",
  templateUrl: "./video-carousel.component.html",
  styleUrls: ["./video-carousel.component.css"],
})
export class VideoCarouselComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {}

  currentSlide = 0;
  videos: { label: any; url: SafeResourceUrl }[];
  videoArray = [];

  sanitiser(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  next() {
    if (this.currentSlide + 1 === this.videoArray.length) return;

    const video = document.getElementById("video");
    const width = video.getBoundingClientRect().width;

    const carousel = document.getElementById("carousel");

    this.currentSlide = this.currentSlide + 1;

    const position = this.currentSlide * width;

    carousel.style.transform = `translateX(-${position}px)`;
  }

  back() {
    if (this.currentSlide === 0) return;

    const video = document.getElementById("video");
    const width = video.getBoundingClientRect().width;

    const carousel = document.getElementById("carousel");
    this.currentSlide = this.currentSlide - 1;
    console.log(this.currentSlide);

    const position = this.currentSlide * width;
    console.log(position);

    carousel.style.transform = `translateX(-${position}px)`;
  }

  ngOnInit(): void {
    this.videos = VIDEOS.map((url) => {
      return { ...url, url: this.sanitiser(url.url) };
    });
  }
}
