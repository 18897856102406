<app-nav></app-nav>
<div class="row">
  <div class="col-md-6 text-center" id="text">
    <h3>Contact Me</h3>
    <p>Feel free to send me a message and I'll get back to you!</p>

    <div id="form">
      <form action="https://formspree.io/xoqkddze" method="POST">
        <label>
          Your email:<br />
          <input style="width: 300px;" type="text" name="_replyto" /> </label
        ><br/>
        <label>
          Your message:<br />
          <textarea
            style="width: 300px;"
            name="message"
            rows="10"
            cols="50"
          ></textarea></label
        ><br />


        <!-- your other form fields go here -->

        <button class="btn btn-dark" type="submit">Send</button>
      </form>
    </div>
  </div>
  <div class="col-md-6">
    <img src="../../assets/imgs/portrait3.jpg" alt="" />
  </div>
</div>
