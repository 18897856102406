import { Component, OnInit } from "@angular/core";
import { COE } from "../content/photos";
import { COE as CDs } from "../content/cds";
import { AUDIO } from "../content/audio";

@Component({
  selector: "app-coe",
  templateUrl: "./coe.component.html",
  styleUrls: ["./coe.component.css"],
})
export class COEComponent implements OnInit {
  photos = COE;
  cds = CDs;
  audio = {
    artist: "",
    title: "",
    audio: "",
    info: "",
  };

  constructor() {}

  ngOnInit(): void {
    console.log(AUDIO);
    this.audio = AUDIO.find((track) => track.title === "Tuba Mirum");
  }
}
