import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgImageFullscreenViewModule } from "ng-image-fullscreen-view";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeroComponent } from "./hero/hero.component";
import { HomeComponent } from "./home/home.component";
import { TeachingComponent } from "./teaching/teaching.component";
import { NavComponent } from "./nav/nav.component";
import { MediaComponent } from "./media/media.component";
import { PerformingComponent } from "./performing/performing.component";
import { FooterComponent } from "./footer/footer.component";
import { ContactComponent } from "./contact/contact.component";
import { BonesapartComponent } from "./bonesapart/bonesapart.component";
import { BbcComponent } from "./bbc/bbc.component";
import { HigginsComponent } from "./higgins/higgins.component";
import { ReviewsComponent } from "./reviews/reviews.component";
import { BiogComponent } from "./biog/biog.component";
import { RecordingComponent } from "./recording/recording.component";
import { PhotoshowComponent } from "./photoshow/photoshow.component";
import { COEComponent } from "./coe/coe.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { ScullyLibModule } from "@scullyio/ng-lib";
import { GalleryComponent } from "./gallery/gallery.component";
import { QuoteTickerComponent } from "./quote-ticker/quote-ticker.component";
import { MediaHeroComponent } from './media-hero/media-hero.component';
import { VideoCarouselComponent } from './video-carousel/video-carousel.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { PhotosComponent } from './photos/photos.component';
import { TrioComponent } from './trio/trio.component';
import { SoloComponent } from './solo/solo.component';

@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    HomeComponent,
    TeachingComponent,
    NavComponent,
    MediaComponent,
    PerformingComponent,
    FooterComponent,
    ContactComponent,
    BonesapartComponent,
    BbcComponent,
    HigginsComponent,
    ReviewsComponent,
    BiogComponent,
    RecordingComponent,
    PhotoshowComponent,
    COEComponent,
    CarouselComponent,
    GalleryComponent,
    QuoteTickerComponent,
    MediaHeroComponent,
    VideoCarouselComponent,
    AudioPlayerComponent,
    PhotosComponent,
    TrioComponent,
    SoloComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgImageFullscreenViewModule,
    BrowserAnimationsModule,
    ScullyLibModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
