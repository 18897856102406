<app-nav></app-nav>

<div class="container-fluid content">
  <h3>Reviews</h3>

       <div class="row">
        <div class="review col-md-5" *ngFor="let review of reviews">
          <h6>
            <strong>{{ review.title }}</strong>
          </h6>
          <p>
            <em>{{ review.content }}</em>
          </p>
          <small>{{ review.author }}</small>
        </div>
      </div>
    </div>
   

