import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-hero',
  templateUrl: './media-hero.component.html',
  styleUrls: ['./media-hero.component.css']
})
export class MediaHeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
