import { Component, OnInit } from '@angular/core';
import { TEACHING } from '../content/photos';

@Component({
  selector: 'app-teaching',
  templateUrl: './teaching.component.html',
  styleUrls: ['./teaching.component.css']
})
export class TeachingComponent implements OnInit {
photos = TEACHING;
  constructor() { }

  ngOnInit(): void {
  }

}
