import { Component, OnInit } from "@angular/core";

import { TRIO } from "../content/photos";
import { AUDIO } from "../content/audio";
import { BONES as BONESCDs } from "../content/cds";

@Component({
  selector: "app-trio",
  templateUrl: "./trio.component.html",
  styleUrls: ["./trio.component.css"],
})
export class TrioComponent implements OnInit {
  photos = TRIO;

  constructor() {}

  ngOnInit(): void {}
}
