export const AUDIO = [
  {
    title: "Bolero",
    artist: "BBCSO",
    cover: "../assets/imgs/bbc/BBCLogo1.png",
    info: "Ravel: Recorded live at the BBC Proms, 2023.",
    audio: "../assets/audio/bolero.mp3 ",
  },
  {
    title: "Mahler 3rd Symphony. 1st Mvt (excerpt 1)",
    artist: "BBCSO",
    cover: "../assets/imgs/bbc/BBCLogo1.png",
    info: "Recorded live at the BBC Proms, 2023.",
    audio: "../assets/audio/Mahler_3_solo_1.mp3 ",
  },
  {
    title: "Mahler 3rd Symphony. 1st Mvt (excerpt 2)",
    artist: "BBCSO",
    cover: "../assets/imgs/bbc/BBCLogo1.png",
    info: "Recordedlive at the BBC Proms, 2023.",
    audio: "../assets/audio/Mahler_3_solo_2.mp3 ",
  },
  {
    title: "Mahler 3rd Symphony. 1st Mvt (excerpt 3)",
    artist: "BBCSO",
    cover: "../assets/imgs/bbc/BBCLogo1.png",
    info: "Recorded live at the BBC Proms, 2023.",
    audio: "../assets/audio/Mahler_3_solos_3.mp3 ",
  },
  {
    title: "Evangile",
    artist: "BBCSO Brass",
    cover: "../assets/imgs/bbc/BBCLogo1.png",
    info: "by Tomasi - Fanfares Liturgiques. Recorded in September 2020.",
    audio: "../assets/audio/TomasiEdited.mp3 ",
  },
  {
    title: "Book of Miracles: trombone concerto by Gavin Higgins",
    artist: "Helen Vollam with the BBC Symphony Orchestra",
    cover: "../assets/imgs/bbc/BBCLogo1.png",
    info: "Excerpts taken from a live performance at The Barbican, London 2019",
    audio: "../assets/audio/HigginsBookOfMiraclesOverview.mp3 ",
  },
  {
    title: "Tuba Mirum",
    artist: "Chamber Orchestra of Europe/Tenebrae/Short",
    info: "Mozart Requiem",
    audio: "../assets/audio/TubaMirum.m4a ",
    cover: "../assets/imgs/COE.png",
  },
  {
    title: "Mahler 3rd Symphony",
    artist: "BBC Symphony Orchestra",
    cover: "../assets/imgs/bbc/BBCLogo1.png",
    info: "(excerpt from 1st mvt) Recorded live at The Barbican, London 2015",
    audio: "../assets/audio/Mahler3_BBCSO.mp3 ",
  },
  {
    title: "Riffs for Everyone",
    artist: "London Symphony Orchestra",
    info: "From 'Prelude, Fugues & Riffs', Bernstein",
    audio: "../assets/audio/RiffsForEveryone.m4a ",
  },
  {
    title: "Variations on Annie Laurie",
    artist: "Bones Apart",
    info: "Pryor arranged Vollam (Ten CD)",
    cover: "../assets/imgs/CDs/CDBA1.jpg",
    audio: "../assets/audio/Annie_Laurie.m4a",
  },
  {
    title: "Habañera",
    artist: "Bones Apart",
    info: "Bizet arranged Vollam (Enigma CD) ",
    cover: "../assets/imgs/CDs/CDBA3.jpg",
    audio: "../assets/audio/Habanera.m4a ",
  },
  {
    title: "Seguidille",
    artist: "Bones Apart",
    info: "Bizet arranged Vollam (Enigma CD) ",
    cover: "../assets/imgs/CDs/CDBA3.jpg",
    audio: "../assets/audio/Seguidille.m4a ",
  },
  {
    title: "My Funny Valentine",
    artist: "Bones Apart",
    info: "Rodgers/Hart arr Challis (Ten CD)",
    cover: "../assets/imgs/CDs/CDBA1.jpg",
    audio: "../assets/audio/Valentine.m4a ",
  },
];
