export const FILMS = [
  { title: "Hotel Transalvania", image: "../assets/imgs/films/poster2.jpg" },
  { title: "No Time to Die", image: "../assets/imgs/films/poster3.jpg" },
  { title: "Live, Die, Repeat", image: "../assets/imgs/films/poster4.jpg" },
  { title: "Final Fantasy", image: "../assets/imgs/films/poster5.jpg" },
  { title: "Harry Potter", image: "../assets/imgs/films/poster6.jpg" },
  { title: "Iron Man 2", image: "../assets/imgs/films/poster7.JPG" },
  { title: "Inception", image: "../assets/imgs/films/poster8.jpg" },
  { title: "Last King", image: "../assets/imgs/films/poster9.jpg" },
  { title: "Star Wars Episode II", image: "../assets/imgs/films/poster10.jpg" },
  { title: "Harry Potter", image: "../assets/imgs/films/poster11.jpg" },
  { title: "Brave", image: "../assets/imgs/films/poster12.JPG" },
  { title: "The Ides of March", image: "../assets/imgs/films/poster13.JPG" },
  { title: "DOGMA", image: "../assets/imgs/films/poster14.jpg" },
  { title: "To End All Wars", image: "../assets/imgs/films/poster15.jpg" },
  { title: "Revelation", image: "../assets/imgs/films/poster16.jpg" },
  {
    title: "Barbie in the Nutcracker",
    image: "../assets/imgs/films/poster17.jpg",
  },
  { title: "The Magic Flute", image: "../assets/imgs/films/poster18.jpg" },
  {
    title: "Harry Potter & The Goblet of Fire",
    image: "../assets/imgs/films/poster19.JPG",
  },
  {
    title: "The League of Extraordinary Gentlemen",
    image: "../assets/imgs/films/poster20.jpg",
  },
  {
    title: "Rise of the Guardians",
    image: "../assets/imgs/films/poster21.jpg",
  },
  { title: "Fred Claus", image: "../assets/imgs/films/poster22.jpg" },
  {
    title: "Around and World in 80 Days",
    image: "../assets/imgs/films/poster23.jpg",
  },
  { title: "Jayne Eyre", image: "../assets/imgs/films/poster24.JPG" },
  { title: "Miss Potter", image: "../assets/imgs/films/poster25.JPEG" },
  { title: "Lost King", image: "../assets/imgs/films/poster26.jpeg" },
];
