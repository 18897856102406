import { Component, OnInit } from '@angular/core';
import { HIGGINS } from '../content/photos';
import { REVIEWS } from '../content/reviews';

@Component({
  selector: 'app-higgins',
  templateUrl: './higgins.component.html',
  styleUrls: ['./higgins.component.css']
})
export class HigginsComponent implements OnInit {
  reviews = REVIEWS;
photos = HIGGINS;
showFlag: boolean = false;
    currentIndex: number = -1;
 
    constructor () {}
    showLightbox(index) {
        this.currentIndex = index;
        this.showFlag = true;
    }
 
    closeEventHandler() {
        this.showFlag = false;
        this.currentIndex = -1;
    }
 
  


  ngOnInit(): void {
  }

}
