import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.css"],
})
export class HeroComponent implements OnInit {
  quoteArray = [
    {
      quote: "exceptional trombone solos",
      src: "The Times",
    },
    {
      quote: "extremely impressive",
      src: "Classical Source",
    },
    {
      quote: "bronzed tone and even phrasing",
      src: "The Times",
    },
    {
      quote: "amazing playing",
      src: "Bachtrack",
    },
    {
      quote:
        "resonant fluid tone, melodious natural phrasing and unflinching accuracy",
      src: "Critics' Circle",
    },
    {
      quote: "a scintillating performance",
      src: "4barsrest",
    },
  ];
  quoteIndex = 0;

  constructor() {}

  ngOnInit(): void {}
}
