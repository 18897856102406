<app-nav></app-nav>

<div class="content">
  <div class="row">
    <div class="col-md-4">
      <img src="../../assets/imgs/trio/trio.jpeg" alt="" />
      <div class="light-text-box">
        <a class="nav-link" routerLink="/contact">Contact me for bookings</a>
      </div>
      <h4 class="title">Photo Gallery</h4>
      <app-photoshow [data]="photos"></app-photoshow>
      <!-- <div>
        <div>
          <a href="https://www.facebook.com/watch/bonesapart/"
            ><i class="fab fa-facebook-square fa-2x"></i
          ></a>

          <a href="https://www.youtube.com/user/BonesApartTbn4tet"
            ><i class="fab fa-youtube-square fa-2x"></i>
          </a>

          <a href="https://www.instagram.com/bones_apart_/"
            ><i class="fab fa-instagram fa-2x"></i
          ></a>
          <a href="https://twitter.com/bonesapart"
            ><i class="fab fa-twitter fa-2x"></i
          ></a>
          <a href="https://itunes.apple.com/gb/artist/bones-apart/id265818464"
            ><i class="fab fa-itunes fa-2x"></i
          ></a>
        </div>
      </div> -->
    </div>
    <div class="col-md-8 text-center">
      <div class="text-justify light-text-box">
        <h1>Piano Trio with Phil Cobb & Elizabeth Burley</h1>
        <p>
          Helen Vollam (trombone)<br />
          Philip Cobb (trumpet)<br />
          Elizabeth Burley (piano)<br />
          Dora Stoutzker Hall, RWCMD, Cardiff Friday 18 October 2024 By Iwan Fox
          4barsrest
        </p>
        <p>
          "A recital performance of perfectly balanced contrasts from three
          musicians in musical synchronisation.
        </p>
        <p>
          Handel’s ‘Happy We’ from his opera <em> ‘Acis and Galatea’</em> summed
          up the ‘joys and charms’ felt in listening to this recital of balanced
          musical synchronisation. Opening with the duet for soprano and tenor
          voice, Philip Cobb and Helen Vollam sparred playfully off each other
          in pastoral counterpoint, never in tension – a feature added to
          throughout by the subtle accompaniment of pianist Elizabeth Burley.
          From then on it was an hour or so of cultured expositions: Dan
          Jenkins’ concise <em>‘Exuberance & Hymn’</em> with its brittle fanfare
          statements leading into a paean of contentment was balanced by the
          rich romanticism of Brahms <em>‘So lass uns wandern!’</em> that
          captured the old composer’s affectionate (if unfulfilled) longing for
          the ‘dark eyes’ of his pupil Elisabeth von Herzogenberg. The clever
          characterisation of Julia Simpson’s entrepreneurial market gardening
          goblin in <em>‘Come Buy!’</em> was a neat mix of the mischievous and
          the melancholic.
        </p>
        Definition of time and place came with the homage to the Hollywood
        compositional greats of Korngold, Williams and Newman in a wonderfully
        played triptych of
        <em>‘Dogberry & Verges’, ‘Born on the 4th July’</em> and ‘Street Scene’.
        Korngold’s character portrait of the two police constables from
        Shakespeare’s ‘Much Ado About Nothing’ captured their dogged seriousness
        and comedic ineptitude to a tee – Dixon of Dock Green meets Officer
        Dibble from Top Cat. The famous trumpet theme from Oliver Stone’s
        anti-war movie ached with the elegiac defiance of an 1970s America of
        lost honour and needless sacrifice, whilst the contrast offered with a
        1930s country of eternal urban optimism and the suave millionaire King
        Vidor fancies of Alfred Newman’s sublime film score was as delight.
        Peter Graham’s <em>‘Meditation’</em> offered a link to the brass band
        hinterland of Philip Cobb (a theme from the test-piece
        <em>‘Triumph of Time’</em>) as he led on cornet with glowing tenderness.
        <p>
          A superb recital closed with Chick Corea’s self-identifying
          <em>‘Armando’s Rhumba’</em> from his 1976 album‘My Spanish Heart’ and
          the delightful encore of a Bach organ prelude."
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- <div class="col-md-4">
      <div
        [ngClass]="track.artist !== 'Bones Apart' ? 'hidden' : null"
        *ngFor="let track of audio"
        class="audioplayer"
      >
        <div class="row">
          <div class="col-md-9">
            <h6>{{ track.title }}</h6>
            <small>{{ track.info }}</small>
            <audio controls>
              <source src="{{ track.audio }}" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
          <div class="col-md-3 d-none d-md-inline">
            <img style="border-radius: 10px" src="{{ track.cover }}" alt="" />
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="col-md-4 text-center">
      <video poster="../../assets/imgs/bones/ww.jpg" controls>
        <source src="../../assets/video/RossiUnBarbaro.mp4" />
      </video>
    </div>
    <div class="col-md-4">
      <iframe
        src="https://www.youtube.com/embed/eUy3dTZIk1M"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/L22gnbFMoRc"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/yiII2nMiass"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/zP419bPv33o"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
 
  <h4>Recordings</h4>
  <app-photoshow [data]="cds"></app-photoshow> -->
  </div>
</div>
