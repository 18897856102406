<app-nav></app-nav>
<div class="content">
  <div class="row">
    <div class="col-md-6">
      <img src="../../assets/imgs/bbc/BBC1.jpg" alt="" />
      <div>
        <a href="https://www.facebook.com/BBCSO"
          ><i class="fab fa-facebook-square fa-2x"></i
        ></a>

        <a href="https://twitter.com/BBCSO"
          ><i class="fab fa-twitter fa-2x"></i
        ></a>
        <a
          href="https://music.apple.com/gb/artist/the-bbc-symphony-orchestra/1121830"
          ><i class="fab fa-itunes fa-2x"></i
        ></a>
      </div>
    </div>

    <div class="col-md-6 text-justify light-text-box">
      <img id="logo" src="../../assets/imgs/bbc/BBCLogo1.png" alt="" />
      <p>
        I have been Principal Trombone of the BBC Symphony Orchestra since 2004.
        Based at the historic Maida Vale Studios in London, the BBC Symphony
        Orchestra undertakes a huge variety of concerts, radio broadcasts,
        recordings, educational work and tours. Aside from the main concert
        season at the Barbican, a real highlight is performing at the iconic
        Royal Albert Hall for the BBC Proms festival each summer, culminating
        with the legendary ‘Last Night of the Proms’, broadcast worldwide. I am
        very proud to be a part of this fantastic and friendly orchestra. My
        BBCSO colleagues are Dan Jenkins (Sub-Principal Trombone), Rob O’Neill
        (Bass Trombone) and Sam Elliott (Tuba). Find out more and follow the
        orchestra's activities at
        <a href="http://www.bbc.co.uk/symphonyorchestra"
          >www.bbc.co.uk/symphonyorchestra</a
        >
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h4>Photo Gallery</h4>
      <app-photoshow [data]="photos"></app-photoshow>
    </div>
    <div class="col-md-3">
      <iframe
        src="https://www.youtube.com/embed/9Ax8lyvuIjY"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/fE0RbPsC9uE"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/8gqp8GIa62c"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="col-md-3">
      <iframe
        src="https://www.youtube.com/embed/i66SG_AMczg"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://www.youtube.com/embed/ZTCNnpVxW2c"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <iframe
        src="https://youtube.com/embed/MNlN_e5b65o"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div>
    <h4>Recordings</h4>
    <app-photoshow [data]="cds"></app-photoshow>
  </div>
</div>
