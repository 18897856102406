<app-nav></app-nav>
<div class="container-fluid content">
  <h3>Helen Vollam</h3>
  <h5>trombone</h5>
  <div class="row section">
    <div class="col-md-4">
      <img src="../../assets/imgs/portrait1.jpg" alt="" />
    </div>
    <div class="col-md-8 light-text-box">
      <p>{{ biog.p1 }}</p>
      <p>{{ biog.p2 }}</p>
      <p>{{ biog.p3 }}</p>
      <p>{{ biog.p4 }}</p>
    </div>
  </div>

  <div class="row section">
    <div class="col-md-8 light-text-box">
      <p>{{ biog.p5 }}</p>
      <p>{{ biog.p6 }}</p>
      <p>{{ biog.p7 }}</p>
      <p>{{ biog.p8 }}</p>
    </div>
    <div class="col-md-4">
      <img src="../../assets/imgs/Higgins/higgins1.jpg" alt="" />
      <img src="../../assets/imgs/bbc/BBC4.jpg" alt="" />
    </div>
  </div>
  <a
    class="btn btn-dark btn-sm"
    href="../../assets/downloads.zip"
    download="HelenVollam_Biog&Photos"
    >Download Biog & Photos <i class="fa fa-download"></i
  ></a>
</div>
