<app-nav></app-nav>
<div class="page-content">
  <div>
    <h3>Media Page</h3>
  </div>
  <div class="audio-container">
    <div class="audio-item" *ngFor="let track of audio">
      <div>
        <h6>{{ track.title }}</h6>
        <small>{{ track.info }}</small>
      </div>

      <audio controls>
        <source src="{{ track.audio }}" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <!-- <div>
      <img style="border-radius: 10px" src="{{ track.cover }}" alt="" />
    </div> -->
    </div>
  </div>

  <div (click)="selectVideo(null)" *ngIf="showModal" class="backdrop"></div>

  <div class="selected-video" *ngIf="showModal">
    <div class="selected-video-content">
      <iframe
        id="video"
        [src]="videoList[selectedVideoIndex].url"
        frameborder="0"
        allow="accelerometer; encrypted-media;
    gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div *ngIf="selectedVideo !== null" class="video-buttons">
        <button (click)="prevVid()">prev</button>
        <button (click)="nextVid()">next</button>
        <button (click)="closeModal()">close</button>
      </div>
    </div>
  </div>

  <h6>Helen Vollam with Elizabeth Burley</h6>
  <p>
    Recorded live at the Royal Welsh College of Music and Drama, Cardiff, UK,
    November 2022
  </p>
  <div class="videos-container">
    <div
      (click)="selectVideo(video)"
      *ngFor="let video of soloVideos; index as i"
    >
      <iframe
        class="video"
        id="video"
        [src]="video.url"
        frameborder="0"
        allow="accelerometer;
          encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>

  <h6>Bones Apart</h6>
  <div class="videos-container">
    <div
      (click)="selectVideo(video)"
      *ngFor="let video of BonesApartVideos; index as i"
    >
      <iframe
        class="video"
        id="video"
        [src]="video.url"
        frameborder="0"
        allow="accelerometer; encrypted-media;
    gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>

  <!-- <h6>More...</h6>
  <div class="videos-container">
    <div *ngFor="let video of otherVideos; index as i">
      <div (click)="selectVideo(video)">
        <iframe
          id="video"
          [src]="video.url"
          frameborder="0"
          allow="accelerometer; encrypted-media;
    gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div> -->
</div>
