<app-hero></app-hero>
<nav class="home-nav navbar navbar-expand-lg navbar-dark bg-dark">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          routerLink="/performing"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          About Helen
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/biog">Biography</a>
          <a class="dropdown-item" routerLink="/portraits">Portraits</a>

          <a
            class="dropdown-item"
            href="../../assets/downloads.zip"
            download="HelenVollam_Biog&Photos"
            >Download Biog & Photos <i class="fa fa-download"></i
          ></a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          routerLink="/performing"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Performing
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/bbc"
            ><img
              class="menu-pic"
              src="../../assets/imgs/bbc/BBCLogo1.png"
            />BBC Symphony Orchestra</a
          >
          <a class="dropdown-item" routerLink="/bones"
            ><img
              class="menu-pic"
              src="../../assets/imgs/LOGO BATQ logo.jpg"
            />Bones Apart</a
          >
          <!-- <a class="dropdown-item" routerLink="/trio"
            ><img
              class="menu-pic"
              src="../../assets/imgs/trio/trio.jpeg"
            />Piano Trio</a
          >
          <a class="dropdown-item" routerLink="/solo"
            ><img
              class="menu-pic"
              src="../../assets/imgs/Higgins/higgins1.jpg"
            />Solo</a
          > -->
          <a class="dropdown-item" routerLink="/coe"
            ><img class="menu-pic" src="../../assets/imgs/COE.png" />Chamber
            Orchestra of Europe</a
          >
          <a class="dropdown-item" routerLink="/higgins"
            ><img
              class="menu-pic"
              src="../../assets/imgs/Higgins/Main.JPG"
            />Higgins Trombone Concerto</a
          >
          <a class="dropdown-item" routerLink="/reviews">Reviews</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/recording">Recording</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/teaching">Teaching</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/media">Media</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/gallery">Gallery</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/contact">Contact</a>
      </li>
    </ul>
  </div>
</nav>

<div class="content">
  <div class="row features">
    <div class="col-md-3 side-border text-center">
      <h5 class="subtitle">Performing</h5>

      <a class="feature-container" routerLink="/bbc">
        <div class="main-box">
          <img id="bbc-pic" src="../../assets/imgs/bbc/BBC11.jpg" alt="" />
          <div class="overlay">
            <img src="../../assets/imgs/bbc/BBCLogo1.png" alt="" />
          </div>
        </div>
        <p class="group-name">BBC Symphony Orchestra</p>
      </a>

      <a class="feature-container" routerLink="/coe">
        <div class="main-box">
          <img src="../../assets/imgs/coe/COE1.jpg" alt="" />
          <div class="overlay">
            <img src="../../assets/imgs/COE.png" alt="" />
          </div>
        </div>
      </a>
      <p class="group-name">Chamber Orchestra of Europe</p>
    </div>

    <div class="col-md-3 text-center side-border">
      <a class="feature-container" routerLink="/bones">
        <div class="main-box">
          <img src="../../assets/imgs/bones/BAcover.jpg" alt="" />
          <div class="overlay">
            <img src="../../assets/imgs/bones/BATQ Logo.png" alt="" />
          </div>
        </div>
        <p class="group-name">Bones Apart Trombone Quartet</p>
      </a>
      <a routerLink="/higgins" class="home-feature-link">
        <h5 id="concerto">The Book of Miracles</h5></a
      >
      <small>a new trombone concerto</small>
      <div>
        <video poster="../../assets/imgs/Higgins/Main.JPG" controls>
          <source src="../../assets/video/HigginsMusicOnly.mp4" />
        </video>
      </div>
      <p>
        Audio, video, photos and more about 'The Book of Miracles', a new
        trombone concerto written by Gavin Higgins for Helen and the BBC
        Symphony Orchestra.
      </p>

      <a routerLink="/higgins">
        <div class="main-box">
          <img src="../../assets/imgs/Higgins/higgins1.jpg" alt="" />
          <div class="overlay">
            <h5 class="more">More...</h5>
          </div>
        </div>
      </a>
      <!-- <a class="feature-container" routerLink="/trio">
        <div class="main-box">
          <img src="../../assets/imgs/trio/trio.jpeg" alt="" />
          <div class="overlay">
            <h5 class="more">More...</h5>
          </div>
        </div>
        <p class="group-name">Piano Trio with Phil Cobb & Liz Burley</p>
      </a> -->

      <!-- <a class="feature-container" routerLink="/solo">
        <div class="main-box">
          <img src="../../assets/imgs/Higgins/higgins1.jpg" alt="" />
          <div class="overlay">
            <h5 class="more">More...</h5>
          </div>
        </div>
        <p class="group-name">Solo</p>
      </a> -->
    </div>
    <div class="col-md-3 text-center side-border">
      <a routerLink="/recording" class="home-feature-link">
        <h5 class="subtitle">Recordings</h5></a
      >
      <div class="row">
        <div class="col-md-6 text-left">
          <p>
            Equally at home in the concert hall and recording studio, Helen's
            sound can be heard on numerous CDs and Blockbuster movie
            soundtracks.
          </p>
        </div>
        <div class="col-md-6">
          <a routerLink="/recording">
            <carousel [slides]="films"></carousel>
          </a>
        </div>
        <div class="row cds">
          <div class="col-6">
            <a routerLink="/recording">
              <img class="cd" src="../../assets/imgs/CDs/CD4.jpg" alt=""
            /></a>
          </div>
          <div class="col-6">
            <a routerLink="/recording">
              <img class="cd" src="../../assets/imgs/CDs/CD7.jpg" alt=""
            /></a>
          </div>
          <div class="col-6">
            <a routerLink="/recording">
              <img class="cd" src="../../assets/imgs/CDs/CD6.jpg" alt=""
            /></a>
          </div>
          <div class="col-6">
            <a routerLink="/recording">
              <img class="cd" src="../../assets/imgs/CDs/CDBA2.jpg" alt=""
            /></a>
          </div>
          <div class="col-6">
            <a routerLink="/recording">
              <img class="cd" src="../../assets/imgs/CDs/CD12.jpg" alt=""
            /></a>
          </div>
          <div class="col-6">
            <a routerLink="/recording">
              <img class="cd" src="../../assets/imgs/CDs/CDBA4.jpg" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 text-center">
      <a routerLink="/teaching" class="home-feature-link">
        <h5 class="subtitle">Teaching</h5></a
      >
      <p>
        Masterclasses, private lessons, group coaching and more. Find out more
        about Helen's education work.
      </p>

      <a routerLink="teaching">
        <div class="main-box">
          <img src="../../assets/imgs/teaching/teaching5.jpg" alt="" />
          <div class="overlay">
            <img src="../../assets/imgs/teaching/teaching7.jpg" alt="" />
          </div>
        </div>
        <img src="../../assets/imgs/teaching/teaching8.jpg" alt="" />
      </a>
    </div>
  </div>
</div>
