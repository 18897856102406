export const BIOG = {
  p1:
    "Helen is Principal Trombone of the BBC Symphony Orchestra and is also a member of the Chamber Orchestra of Europe and Bones Apart Trombone Quartet. She enjoys a busy and varied career combining orchestral playing, chamber music, solo work and teaching.",
  p2:
    "Helen was appointed Principal Trombone of the BBC Symphony Orchestra in 2004, and is the first woman to be appointed principal trombone of a London orchestra. With the BBCSO she performs in their Barbican concert season, studio concerts, recordings and tours, as well as performing around a dozen concerts at the BBC Proms each year, including the legendary ‘Last Night of the Proms’, broadcast worldwide.",
  p3:
    "Helen studied at the Guildhall School of Music and Drama in London with Eric Crees and Simon Wills. She was Principal Trombone of the European Union Youth Orchestra and won the Bronze Medal in the 1995 Shell/LSO Music Scholarship competition. Since graduating she freelanced with most of the UK’s orchestras and ensembles and has toured throughout Europe, South America, United States, Japan, China and Russia. In 2003 she won the Woodwind & Brass Award and Philip Jones Memorial Prize in the final of the Royal Over-Seas League Music Competition. She was the first ever trombone player to reach the competition’s final.",
  p4:
    "In demand as a teacher and adjudicator, Helen has given classes at the Guildhall School of Music and Drama, Royal Academy of Music, Royal College of Music, Royal Northern College of Music, Trinity Laban Conservatoire of Music and Dance, Royal Conservatoire of Scotland, Wells Cathedral School and the Purcell School. She was on the faculty for the 2017 Dublin Brass Week and has adjudicated for BBC Young Musician, Royal Over-Seas League, BBC Radio 2 Young Brass Award, British Trombone Society, International Trombone Association, Ian Bousfield/Getzen International Solo Competition and Swisstbone Competitions. Helen has recently become Visiting Artist in Trombone at the Royal Birmingham Conservatoire. ",
  p5:
    "She has worked with conductors and artists such as Bernard Haitink, Carlo Maria Giulini, Nikolaus Harnoncourt, Claudio Abbado, Pierre Boulez, Mariss Jansons, Colin Davis, Paavo Berglund, Yannick Nezet-Seguin, Sakari Oramo, Valery Gergiev, Seiji Ozawa, Semyon Bychkov, Simon Rattle, Andrew Davis, Antonio Pappano, André Previn, John Adams, John Williams, Jerry Goldsmith, Bryn Terfel, Natalie Cole, Jules Buckley, Laura Mvula, Lianne La Havas and Monty Python. ",
  p6:
    "Helen has played on many film soundtrack recordings, including Star Wars: The Attack of the Clones, Harry Potter and the Order of the Phoenix, Final Fantasy, Inception, The Last King of Scotland, Hotel Transylvania, The Ides of March, Iron Man 2, Brave and No Time to Die.",
  p7:
    "Also an accomplished arranger, she has published many of her trombone ensemble arrangements with Bones Apart Publishing. In 2017 she was the recipient of the British Trombone Society's Sheila Tracy Award for 'significant contribution to her field'.",
  p8:
    "In 2019 Helen gave the world premiere of Gavin Higgins' new Trombone Concerto 'Book of Miracles', written specially for her, with the BBC Symphony Orchestra at the Barbican in London. In December 2019 the 'Book of Miracles' won Higgins an Ivors Award for Best Orchestral Composition. Helen was awarded ‘Player of the Year’ by the British Trombone Society in 2022.",
};
