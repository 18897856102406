export const REVIEWS = [
  {
    title: "BBCSO/Oramo - Sibelius 7",
    content: ` Oramo allowed the music to speak for itself, as he did with the Sixth, never imposing his own musical ego on proceedings. What came through was the nobility of the conception, held together by the three beautiful C major trombone solos, played with rich rounded tone by Helen Vollam, culminating in one of the most powerful, striving C major cadences in all music.`,
    short: ``,
    author: "Chris Garlick Bachtrack, 10 December 2023",
  },
  {
    title: "Mahler Symphony No.3: (BBC Proms)",
    content: ` … trombonist Helen Vollam continuing to orate and lyricise significantly as the coda got nearer and broke through unstoppably.`,
    short: ``,
    author: "Colin Anderson, Colins Column, 19 August 2023",
  },
  {
    title: "Mahler Symphony No.3: (BBC Proms)",
    content: `Elsewhere, the three trombone solos were coloured with rich character, first defiant, then mournful, then nostalgic. `,
    short: ``,
    author: "Rohan Shotton, Bachtrack, 21 August 2023",
  },
  {
    title: "Mahler Symphony No.3: (BBC Proms)",
    content: `The three incursions of the solo trombone reverted to the opening tempo; here they were powerful apparitions of ’the heat, the stillness, the Pan-ic terror’, in Alma’s memorable phrase.`,
    short: ``,
    author: "Chris Kettle, Seen and Heard International, 23 August 2023",
  },
  {
    title: "Mahler Symphony No.3: (BBC Proms)",
    content: `This piece faces the awesome power of nature in all its beauty and horror - and conductor Sakari Oramo masterfully paced its complex 95-minute journey. There was an appealing steadiness to his approach, but it never dimmed the drama, or his instinct for when to let the music draw us in - nowhere more so than in Helen Vollam’s exceptional trombone solos.`,
    short: `exceptional trombone solos`,
    author: "Rebecca Franks, The Times, 21 August 2023",
  },
  {
    title: "Higgins ‘Book of Miracles’, Trombone Concerto.",
    content: `“…a well-crafted soundscape and a welcome showcase for the bronzed tone and even phrasing of BBC Symphony Orchestra's principal trombonist, Helen Vollam.”
    `,
    short: `the bronzed tone and even phrasing of BBC Symphony Orchestra's principal trombonist, Helen Vollam`,
    author: "Neil Fisher, The Times, 14 February 2019",
  },
  {
    title: "Higgins ‘Book of Miracles’, Trombone Concerto.",
    content: `“ The solo part, often stentorian and declamatory as we expect from a trombone, was also lyrical, and virtuosic in its frequent demands for flexibility and fun 
        (lip trills, glissandi). It never became high-wire act virtuosity, perhaps because Helen Vollam’s amazing playing seemed to make light of any difficulties. Her orchestral colleagues were enthusiastic in their welcome before she had played a note, and more so after the performance. As was a supporters’ club of about a dozen (friends and family?) who leapt to their feet. That supporters’ club increased its membership a hundredfold last night.”`,
    short: `Helen Vollam’s amazing playing seemed to make light of any difficulties`,
    author: "Roy Westbrook, Bachtrack, 15 February 2019",
  },
  {
    title: "Higgins ‘Book of Miracles’, Trombone Concerto.",
    content: `“ The central item was by Gavin Higgins (born 1983). Inspired by The Book of Miracles – a sixteenth-century German manuscript that depicts biblical stories from the Old Testament and the book of Revelation, as well as miracles and natural and supernatural phenomena – this substantial thirty-minute Concerto proved to be a perfect vehicle for Helen Vollam (BBCSO principal), her trombone ideally suiting the declamatory role.
        The work opens calmly before progressing through a series of arresting passages between stentorian soloist and the strings, brass and percussion. A brass chorale is an important element and there is a notable passage where time seems to come to a standstill. In the second movement, exchanges between the trombone (now muted) and orchestra, though briefly interrupted, lead to a fragile, almost spectral, conclusion. Virtuosity is to the fore in the penultimate movement – cadenza-like in both orchestral and trombone terms – 
        and the Finale, which has aggressive and portentous elements alternating with recollections of earlier content, presses on towards a commanding conclusion. This first outing was extremely impressive. ”
        `,
    short: `This first outing was extremely impressive.`,
    author: "Richard Landau, Classical Source, 13 February 2019",
  },
  {
    title: "Higgins ‘Book of Miracles’, Trombone Concerto.",
    content: `“ Vollam gave a scintillating performance of 'Book of Miracles' — a work inspired by the pages of an illuminated 16th century manuscript packed full of incredible mythical monsters, comets, eclipses and mock suns called parhelia. The critics were certainly impressed, with Roy Westbrook writing for the influential Backtrack.com saying; "For all the portentousness that (the title) suggests, Higgins never forgets that the concerto form has a playful element, at least some of the time in a fascinating work which has plenty of variety within its sense of unity." Meanwhile, 'Classical Source' called it "extremely impressive", and a "perfect vehicle for Helen Vollam (BBCSO principal), her trombone ideally suiting the declamatory role." Writing in The Times newspaper, Neil Fisher described it as: "a well-crafted soundscape and a 
        welcome showcase for the bronzed tone and even phrasing of BBC Symphony Orchestra's principal trombonist, Helen Vollam."`,
    short: `Vollam gave a scintillating performance of 'Book of Miracles'`,
    author: "4barsrest, 19 February 2019",
  },
  {
    title: "Shostakovich Symphony No.15:",
    content: `“ The long slow movement switched from fun to profundity. Several leaders have solos here, and those from the 'cello of David Cohen near the start and the funereal trombone of Helen Vollam stood out.”`,
    short: `the funereal trombone of Helen Vollam stood out.`,
    author: "Roy Westbrook, Bachtrack, 7 February 2022",
  },
  {
    title: "Mahler Symphony No.3:",
    content: `“ Chief Conductor Sakari Oramo pointed straight to Helen Vollam as first of the soloists to be applauded and she rose from her seat to a roar. She had played her epic first movement solos with resonant fluid tone, melodious natural phrasing and unflinching accuracy.”`,
    short: `She had played her epic first movement solos with resonant fluid tone, melodious natural phrasing and unflinching accuracy.`,
    author: "Rick Jones, Critics Circle, 25 September 2015",
  },
  {
    title: "Mahler Symphony No.3:",
    content: `“ We even had the same peerless trombonist, Helen Vollam, awesome in the primeval funeral marches of the first movement, amid a mix of distinguished orchestral soloists old and new.”`,
    short: `Helen Vollam, awesome in the primeval funeral marches of the first movement`,
    author: "David Nice, The Arts Desk, 25 September 2015",
  },
  {
    title: "Mahler Symphony No.3:",
    content: `“ Belohlavek brought out Mahler's vivid tricks of orchestration and, with resonant trombone solos from Helen Vollam, wound up the springy, major-key march into euphoric climaxes.”`,
    short: `resonant trombone solos from Helen Vollam`,
    author: "Erica Jeal, The Guardian, 10 April 2007",
  },
  {
    title: "Mahler Symphony No.3:",
    content: `“ The brass was particularly impressive: the eight horns made a splendid sound in the opening awakening of Pan, in which Helen Vollam’s solo trombone had an ominous urgency.”`,
    short: `Helen Vollam’s solo trombone had an ominous urgency.`,
    author: "Matthew Rye, Telegraph, 6 April 2007",
  },
  {
    title: "Shostakovich Symphony No.4:",
    content: `“ Perfectly honed solos guided us through the twilight zones, from Julie Price’s lone bassoon to Helen Vollam’s belated headlining as the music-hall trombonist cavorting through the dance-suite that turns out to be the calm before the ultimate storm.” `,
    short: `Helen Vollam’s belated headlining as the music-hall trombonist cavorting through the dance-suite`,
    author: "David Nice, The Arts Desk, 4 October 2012",
  },
  {
    title: "Gary Carpenter - Dadaville:",
    content: `“ Although short, the piece has a piecemeal sectional structure, but is brought together at the end by a riotous jazzy finale, capped by an impressive trombone break from Helen Vollam.”`,
    short: `a riotous jazzy finale, capped by an impressive trombone break from Helen Vollam.`,
    author: "Gavin Dixon, The Arts Desk, 18 July 2015",
  },
  {
    title: "Sibelius - Symphony No.7:",
    content: `“ Saraste’s more comprehensively individual approach to the Seventh was one I could warm to, at least in the way he built swiftly yet organically to the first of Helen Vollam’s effortlessly voiced trombone-solo cornerstones...”`,
    short: `Helen Vollam’s effortlessly voiced trombone-solo cornerstones...`,
    author: "David Nice, The Arts Desk, 17 December 2011",
  },
  {
    title: "Sibelius - Symphony No.7:",
    content: `“ Helen Vollam’s trombone solos were dispatched with great dignity.”`,
    short: `“ Helen Vollam’s trombone solos were dispatched with great dignity.”`,
    author: "Mark Pullinger, Bachtrack, 18 August 2015",
  },
  {
    title: "Ravel - Bolero:",
    content: `“ The solos of this unique concerto for orchestra got off to a classy start with flautist Michael Cox, and peaked in expressiveness with the multiple nudges Helen Vollam brought to the not-so-lounge lizard trombone solo.”`,
    short: `peaked in expressiveness with the multiple nudges Helen Vollam brought to the not-so-lounge lizard trombone solo.”`,
    author: "David Nice, The Arts Desk, 11 April 2015",
  },
  {
    title: "Ligeti - Violin Concerto:",
    content: `“ Stratospherically high horn lines, excellently played by Alec Frank-Gemmill, and frenzied, almost free jazz lines, fierily delivered by trombonist Helen Vollam, stood out.”`,
    short: `fierily delivered by trombonist Helen Vollam`,
    author: "Alan Coady, Bachtrack, 23 March 2014",
  },
  {
    title: "Elliott Carter - Three Occasions:",
    content: `“ Knussen now brings greater weight to Three Occasions (1989) – a 'sinfonia brevis' lacking nothing in gravity or exuberance (and with finely attuned trombone playing from Helen Vollam in ‘Remembrance’)”`,
    short: `finely attuned trombone playing from Helen Vollam in ‘Remembrance’`,
    author: "Richard Whitehouse, Classical Source, 31 January 2006",
  },
  {
    title: "Elliott Carter - Three Occasions:",
    content: `“ In Remembrance, the second Occasion, a trombone solo, expressively played by Helen Vollam, seemed to evoke a deeply resonant human voice. Many of its notes stood alone, floating in silence, like the slow tolling of a bell.”`,
    short: `a trombone solo, expressively played by Helen Vollam, seemed to evoke a deeply resonant human voice`,
    author: "Anne Ozorio, Music Web International, 13 January 2006",
  },
];
