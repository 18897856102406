import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { TeachingComponent } from "./teaching/teaching.component";
import { MediaComponent } from "./media/media.component";
import { BbcComponent } from "./bbc/bbc.component";
import { BonesapartComponent } from "./bonesapart/bonesapart.component";
import { ContactComponent } from "./contact/contact.component";
import { HigginsComponent } from "./higgins/higgins.component";
import { ReviewsComponent } from "./reviews/reviews.component";
import { BiogComponent } from "./biog/biog.component";
import { RecordingComponent } from "./recording/recording.component";
import { COEComponent } from "./coe/coe.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { PhotosComponent } from "./photos/photos.component";
import { TrioComponent } from "./trio/trio.component";
import { SoloComponent } from "./solo/solo.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "teaching", component: TeachingComponent },
  { path: "media", component: MediaComponent },
  { path: "bbc", component: BbcComponent },
  { path: "bones", component: BonesapartComponent },
  { path: "contact", component: ContactComponent },
  { path: "higgins", component: HigginsComponent },
  { path: "reviews", component: ReviewsComponent },
  { path: "biog", component: BiogComponent },
  { path: "recording", component: RecordingComponent },
  { path: "coe", component: COEComponent },
  { path: "portraits", component: GalleryComponent },
  { path: "gallery", component: PhotosComponent },
  { path: "trio", component: TrioComponent },
  { path: "solo", component: SoloComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
