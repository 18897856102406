<app-nav></app-nav>
<div class="light-text-box solo-top">
  <div class="solo-portrait">
    <img class="hero-image" src="../assets/imgs/portrait1.jpg" alt="" />
  </div>
  <div class="solo-text">
    <h1>Solo Work</h1>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. A eligendi
      laboriosam, tenetur quae, autem provident cum neque veritatis expedita
      necessitatibus quidem consectetur sed dolores dolor eos aliquid laborum
      voluptatem dolorem culpa facilis blanditiis itaque quas, doloribus ut.
      Magnam magni quidem facere fuga repellat esse, tempora omnis eos aliquam
      numquam saepe cum animi minima alias harum facilis et dolorum ex, quis
      quod dignissimos itaque dolores quibusdam! Incidunt ullam earum quod.
      Dolorum, ullam molestiae. Ea dignissimos a distinctio, ex ut, at
      cupiditate impedit, quidem veniam laborum harum? Vero unde aspernatur
      culpa aliquid id. Suscipit consectetur ab ipsam voluptatem amet, dolorem
      sed explicabo corrupti est quos molestias voluptates consequuntur, sint
      maxime perspiciatis.
    </p>
  </div>
</div>
<div class="solo-section">
  <div class="section-half">
    <app-media-hero></app-media-hero>
  </div>
  <div class="section-half text-center">
    <h3>The Book of Miracles</h3>

    <small>a new trombone concerto</small>

    <p class="description">
      Helen premiered Gavin Higgins’ new trombone concerto ‘The Book of
      Miracles’ in February 2019 at the Barbican in London with the BBC Symphony
      Orchestra conducted by Alexander Vedernikov.
    </p>

    <video poster="../../assets/imgs/Higgins/Main.JPG" controls>
      <source src="../../assets/video/HigginsMusicOnly.mp4" />
    </video>

    <a class="button next-button" routerLink="/higgins"> Read more </a>
  </div>

  <div>AUDIO TRACKS HERE</div>
</div>
