export const BBC = [
{url:'https://www.youtube.com/embed/9Ax8lyvuIjY'},
{url:'https://www.youtube.com/embed/fE0RbPsC9uE'},
{url:'https://www.youtube.com/embed/8gqp8GIa62c'},
{url:'https://www/youtube.com/embed//8Go9VpiGt6M'},
{url:'https://www.youtube.com/embed/nDqEBUFRWs8'},
{url:'https://www.youtube.com/embed/ZTCNnpVxW2c'},
{url:'https://www.youtube.com/embed/ukmCEQKk1iM'},

];


