<nav class="page-nav navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" routerLink="/">Helen Vollam</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink=""
          >Home <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          routerLink="/performing"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          About Helen
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/biog">Biography</a>
          <a class="dropdown-item" routerLink="/portraits">Portraits</a>
          <a
            class="dropdown-item"
            href="../../assets/downloads.zip"
            download="HelenVollam_Biog&Photos"
            >Download Biog & Photos <i class="fa fa-download"></i
          ></a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          routerLink="/performing"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Performing
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/bbc"
            ><img
              class="nav-icon"
              src="../../assets/imgs/bbc/BBCLogo1.png"
            />BBC Symphony Orchestra</a
          >
          <a class="dropdown-item" routerLink="/bones"
            ><img
              class="nav-icon"
              src="../../assets/imgs/LOGO BATQ logo.jpg"
            />Bones Apart</a
          >
          <!-- <a class="dropdown-item" routerLink="/trio"
            ><img
              class="nav-icon"
              src="../../assets/imgs/trio/trio.jpeg"
            />Piano Trio</a
          >
          <a class="dropdown-item" routerLink="/solo"
            ><img
              class="nav-icon"
              src="../../assets/imgs/Higgins/higgins1.jpg"
            />Solo</a
          > -->
          <a class="dropdown-item" routerLink="/coe"
            ><img class="nav-icon" src="../../assets/imgs/COE.png" />Chamber
            Orchestra of Europe</a
          >
          <a class="dropdown-item" routerLink="/higgins"
            ><img
              class="nav-icon"
              src="../../assets/imgs/Higgins/Main.JPG"
            />Higgins Trombone Concerto</a
          >

          <a class="dropdown-item" routerLink="/reviews">Reviews</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/teaching">Teaching</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/recording">Recording</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/media">Media</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/gallery">Gallery</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/contact">Contact</a>
      </li>
    </ul>

    <!-- <div class="ml-auto">
    <a href="https://www.facebook.com/helen.vollam"><i class="fab fa-facebook-f "></i></a>
  </div> -->
  </div>
</nav>
